import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { TranslocoDirective } from '@jsverse/transloco';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

@Component({
  standalone: true,
  imports: [TranslocoDirective, ChrComponentsModule],
  templateUrl: './document-download-confirmation-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDownloadConfirmationDrawerComponent {
  private readonly dialogRef = inject(DialogRef<boolean>);

  readonly dialogData = inject<{ title?: string }>(DIALOG_DATA);

  close(downloadConfirmed: boolean): void {
    this.dialogRef.close(downloadConfirmed);
  }
}
