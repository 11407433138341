<chr-modal *transloco="let t" size="s" [closable]="true" (chr-modal-close)="close(false)">
  <chr-modal-header [title]="dialogData && dialogData.title || t('COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.TITLE')" />
  <chr-modal-body>
    <p data-test-id="documentDownloadDescription">{{ t("COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.DESCRIPTION") }}</p>
  </chr-modal-body>
  <chr-modal-footer>
    <chr-button
      [label]="t('COMMON.ACTIONS.CANCEL')"
      palette="neutral"
      variant="invisible"
      data-test-id="documentDownloadCancelButton"
      (chr-action)="close(false)"
    />
    <chr-button [label]="t('COMMON.ACTIONS.DOWNLOAD')" data-test-id="documentDownloadButton" (chr-action)="close(true)" />
  </chr-modal-footer>
</chr-modal>
